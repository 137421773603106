import React, { useState } from "react";
import "./Work.css";
import linkedin from "../images/linkedin.png";
import nft from "../images/nft-plateform.png";
import gmail from "../images/gmail.png";
import iphone from "../images/iphone-site.png";
import { ReactComponent as Firebase } from "../images/firebase-icon.svg";
import { ReactComponent as ReactJS } from "../images/reactjs-icon.svg";
import { ReactComponent as CSS } from "../images/icons8-css3.svg";
import { ReactComponent as Vuejs } from "../images/icons8-vuejs (1).svg";
import { ReactComponent as Html } from "../images/w3_html5-icon.svg";
import { ReactComponent as Js } from "../images/icons8-javascript.svg";
import { ReactComponent as MySQL } from "../images/mysql-official.svg";
import { ReactComponent as Thirdweb } from "../images/thirdweb.svg";
import { ReactComponent as Typescript } from "../images/typscript.svg";
import { ReactComponent as Nextjs } from "../images/nextjs.svg";
import { ReactComponent as Sanity } from "../images/sanity.svg";
import { ReactComponent as Gsap } from "../images/gsap.svg";
import { ReactComponent as Blender } from "../images/icons8-blender-3d.svg";

function Work() {
  const [showCard, setshowCard] = useState("false");
  const changeCard = () => {
    if (window.scrollY >= 250) {
      setshowCard(true);
    } else {
      setshowCard(false);
    }
  };

  window.addEventListener("scroll", changeCard);
  return (
    <div className="work" id="work">
      <h1>Projets</h1>
      <div className={`work__card1 ${showCard ? "showCard" : ""} `}>
        <div className="work__number1">01</div>
        <img src={nft} alt=" NFT" />
        <div className="work__button1">
          <button>
            <a
              href="https://nft-77ayaym9v-gabrielver.vercel.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Voir le site
            </a>
          </button>
          <button>
            <a
              href="https://github.com/gabrielver/nft"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </button>
        </div>
        <div className="work__info2  ">
          <h1>NFT Drop</h1>
          <p>
            Plateforme de lancement de collections NFT web 3.0 sur la blockchain
            Ethereum, connexion avec le wallet Metamask et possibilité de "mint"
            en payant la transaction grace à metamask. <br />
            Projet réalisé avec NextJS et Sanity pour le backend.
          </p>
          <div className="work__icon1">
            <Nextjs className="icon" />
            <Thirdweb className="icon" />
            <Typescript className="icon" />
            <Sanity className="icon" />
          </div>
        </div>
      </div>

      <div className={`work__card2 ${showCard ? "showCard2" : ""} `}>
        <div className="work__number2">02</div>
        <img src={linkedin} alt=" linkedin" />
        <div className="work__button2">
          <button>
            <a
              href="https://linkedin-clone-c192c.web.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Voir le site
            </a>
          </button>
          <button>
            <a
              href="https://github.com/gabrielver/Linkedin-Clone"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </button>
        </div>
        <div className="work__info1">
          <h1>Linkedin Clone</h1>
          <p>
            Création d'un clone partiel du site Linkedin. Authentification,
            création de "post" et affichage instantané du "post" dans le fils
            d'actualité. Projet réalisé avec ReactJS
          </p>
          <div className="work__icon1">
            <ReactJS className="icon" />
            <Firebase className="icon" />
            <Html className="icon" />
            <CSS className="icon" />
          </div>
        </div>
      </div>

      <div className={`work__card3 ${showCard ? "showCard" : ""} `}>
        <div className="work__number1">03</div>
        <img src={iphone} alt=" gmail" />
        <div className="work__button1">
          <button>
            <a
              href="https://animation-gsap.web.app/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Voir le site
            </a>
          </button>
          <button>
            <a
              href="https://github.com/gabrielver/Animation-gsap"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </button>
        </div>
        <div className="work__info2">
          <h1>Animation with Gsap</h1>
          <p>
            Site vitrine pour présentation de l'iphone 13. Test et integration
            de differents types d'animations utilisant le scroll et des videos
            3D réalisées avec Blender
          </p>
          <div className="work__icon2">
            <Gsap className="icon" />
            <Js className="icon" />
            <Html className="icon" />
            <CSS className="icon" />
            <Blender className="icon" />
          </div>
        </div>
      </div>

      <div className={`work__card2 4 ${showCard ? "showCard2" : ""} `}>
        <div className="work__number2">04</div>
        <img src={gmail} alt=" gmail" />
        <div className="work__button2">
          <button>
            <a
              href="https://clone-6eed7.web.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Voir le site
            </a>
          </button>
          <button>
            <a
              href="https://github.com/gabrielver/GabMail-clone-"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </button>
        </div>
        <div className="work__info1">
          <h1>Gmail Clone</h1>
          <p>
            Création de l'interface utilisateur de Gmail. Authentification par
            compte gmail, création et envoi d'un formulaire "mail", réception
            des"mails" et affichage de ce dernier . Réalisé avec ReactJS
          </p>
          <div className="work__icon2">
            <ReactJS className="icon" />
            <Firebase className="icon" />
            <Html className="icon" />
            <CSS className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
